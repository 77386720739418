import React from 'react';
import { cventry, period, location, entrycontent } from '../components/cventry/cventry.module.css';

interface CvDate {
    month: string;
    year: number;
}
interface Props {
    title: string;
    company: string;
    location: string;
    start: CvDate;
    end?: CvDate;
    children?: any;
}
function getDateEntry(startDate: CvDate, endDate: CvDate): string {
    let date = `${startDate.month.toLowerCase()} ${startDate.year} - `;

    if (endDate) {
        date += `${endDate.month} ${endDate.year}`;
    } else {
        date += 'present';
    }

    return date;
}

const CvEntry: React.FunctionComponent<Props> = (data: Props) => (
    <article className={cventry}>
        <p className={period}>{getDateEntry(data.start, data.end)}</p>
        <h4 className={location}>{data.location}</h4>
        <div className={entrycontent}>
            <h3>{data.title}</h3>
            <h4>{data.company}</h4>
            <p>{data.children}</p>
        </div>
    </article>
);

export default CvEntry;
