import React from 'react';
import Menu from '../components/menu';
import CvEntry from '../components/cventry';
import SkillSetComponent from '../components/skillset';

const CvPage: React.FunctionComponent = () => (
    <div className="application">
        <Menu />
        <div className="page-container">
            <section>
                <div className="experience">
                    <h2 className="cv-section-title">experience</h2>
                    <CvEntry
                        title="Technical consultant"
                        company="Netcompany"
                        location="Copenhagen"
                        start={{ month: 'oct', year: 2019 }}
                    >
                        Currently assigned to a project with The Danish Labor Market Insurance to develop a new Case
                        Management System, working in teams of 3-5. Designing, building and testing a self-service
                        portal that will have up to 100,000 unique monthly users using .NET core and Angular.
                    </CvEntry>
                    <CvEntry
                        title="Web Developer"
                        company="Rentbuyestate"
                        location="Copenhagen"
                        start={{ month: 'mar', year: 2015 }}
                        end={{ month: 'aug', year: 2016 }}
                    >
                        Development and configuration of Webcrawlers for major European housing portals. Mainly working
                        with .NET, XPath, Regex and Selenium used for scraping housing sites for postings.
                    </CvEntry>
                </div>
                <div className="education">
                    <h2 className="cv-section-title">education</h2>
                    <CvEntry
                        title="MS in Computer Science"
                        company="Korea Advanced Institute of Science and Technology"
                        location="Deajeon"
                        start={{ month: 'feb', year: 2018 }}
                        end={{ month: 'jun', year: 2019 }}
                    >
                        Relevant Coursework: Human-Computer Interaction, Social Computing, AI-based Software Engineering
                        and Distributed Systems. Thesis: Improving Learning plans with Peer goal revision. Prototype
                        iterations developed in ReactJS.
                    </CvEntry>
                    <CvEntry
                        title="MSc. Computer Science and Engineering"
                        company="Technical Univerity of Denmark"
                        location="Kongens Lyngby"
                        start={{ month: 'sep', year: 2017 }}
                        end={{ month: 'jun', year: 2019 }}
                    >
                        Relevant Coursework: Machine Learning, Computationally Hard problems.
                    </CvEntry>
                    <CvEntry
                        title="BSc. Software Technology"
                        company="Technical Univerity of Denmark"
                        location="Kongens Lyngby"
                        start={{ month: 'sep', year: 2014 }}
                        end={{ month: 'jun', year: 2017 }}
                    >
                        Relevant Coursework: UX Engineering, Advanced Algorithms and Multi-agent AI Systems. Thesis:
                        Attacking and Safeguarding Critical infrastructure.
                    </CvEntry>
                    <CvEntry
                        title="BSc Scheme in Computing"
                        company="The Hong Kong Polytechnic University"
                        location="Hong Kong"
                        start={{ month: 'sep', year: 2016 }}
                        end={{ month: 'jan', year: 2017 }}
                    >
                        As a part of my bachelor at DTU I spent my 5th semester abroad in Hong Kong. {''}
                        Relevant coursework: Database design, Web application development
                    </CvEntry>
                </div>
                <div className="skill">
                    <h2 className="cv-section-title">skills</h2>
                    <SkillSetComponent
                        title="languages"
                        skills={[
                            { name: 'C#', level: 80 },
                            { name: 'Javascript', level: 70 },
                            { name: 'CSS', level: 70 },
                            { name: 'HTML', level: 60 },
                            { name: 'Python', level: 50 },
                            { name: 'SQL', level: 40 },
                            { name: 'PHP', level: 40 },
                        ]}
                    ></SkillSetComponent>
                    <SkillSetComponent
                        title="frameworks"
                        skills={[
                            { name: '.Net Core', level: 80 },
                            { name: 'Angular', level: 60 },
                            { name: 'React', level: 60 },
                            { name: 'GatsbyJs', level: 70 },
                            { name: 'React-native', level: 30 },
                        ]}
                    ></SkillSetComponent>
                    <SkillSetComponent
                        title="tools"
                        skills={[
                            { name: 'Git', level: 80 },
                            { name: 'Azure DevOps', level: 50 },
                            { name: 'Android Development', level: 50 },
                            { name: 'Unix', level: 50 },
                            { name: 'NodeJs', level: 40 },
                        ]}
                    ></SkillSetComponent>
                    <SkillSetComponent
                        title="methodologies"
                        skills={[
                            { name: 'Agile/SCRUM', level: 80 },
                            { name: 'User Testing', level: 60 },
                            { name: 'Quality Assurance', level: 60 },
                            { name: 'Rapid Prototyping', level: 60 },
                        ]}
                    ></SkillSetComponent>
                </div>
            </section>
            <footer>
                <p>&copy; 2021 Mathias Sixten Pedersen</p>
            </footer>
        </div>
    </div>
);

export default CvPage;
