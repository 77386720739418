import React from 'react';
import {skillset} from '../components/skillset/skillset.module.css';

interface SkillProp {
    name: string;
    level: number;
}

interface Props {
    title: string;
    skills: SkillProp[];
}

function levelToColor(level: number): string {
    level = level === 0 ? 1 : level;
    const redChannel = 200 * (1 - level / 100) * 0.6;
    const greenChannel = 200 * (80 / 100);
    return `rgba(${redChannel},${greenChannel}, ${greenChannel}, ${level / 100})`;
}

const SkillSetComponent: React.FunctionComponent<Props> = (data: Props) => {
    const skillTags = [];
    for (const skill of data.skills) {
        skillTags.push(
            <li
                key={skill.name}
                style={{ color: levelToColor(skill.level), border: '1px solid ' + levelToColor(skill.level) }}
            >
                {skill.name}
            </li>,
        );
    }

    return (
        <div className={skillset}>
            <h3>{data.title}</h3>
            <ul>{skillTags}</ul>
        </div>
    );
};

export default SkillSetComponent;
